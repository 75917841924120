import { Button, Col, Row, Form, Alert } from 'react-bootstrap';

import { Helmet } from "react-helmet";
import React, { useState } from 'react';
import Slider from 'components/slider';
import icon from 'images/icon.svg';
import Layout from 'components/layout';
import axios from 'axios';

const Contact = () => {

    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');

    const resetFormData = () => {
        setEmail('');
        setMessage('');
        setName('');
        setCompany('');
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name: name,
            email: email,
            company: company,
            message: message
        };
        submitData(data);
        resetFormData();
    };

    const submitData = async (data) => {
        const options = {
            method: "POST",
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: "https://script.google.com/macros/s/AKfycbwu-r4XdO1qoQ55E3bQ-Z0Mq9PQpgrUhrjOGLjsF3NUnGPC2dLUwUHDx0LycsYivdZnKg/exec?sheetName=contact",
            data: JSON.stringify(data)
        }
        //eslint-disable-next-line
        const response = await axios(options);
        return <Alert variant="success">We have received your message, Our Team will get back to you soon!</Alert>
    }

    return (
        <Layout>
            <div style={{ width: '100%' }}>
                <Helmet>
                    <link rel="icon" href={icon} />
                    <title>We Are Just a Message Away. Write to Us!!!</title>
                    <meta name="description" content="Among the people who have contacted us, 3% have become our customers, 0.5% have become our employees and 0.2% have become our partners.m" />
                </Helmet>
                <div className="home">
                    <div>
                        <p className="title">We Are Just a Message Away. Write to Us!!!</p>
                        <p className="description">FACT: Among the people who have contacted us, 3% have become our customers, 0.5% have become our employees and 0.2% have become our partners.</p>
                    </div>
                </div>
                <Row style={{ marginBottom: '50px', marginTop: '50px' }}>
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 5, offset: 1 }} style={{ background: 'rgba(196, 101, 255, 0.3)', borderRadius: '20px', marginBottom: '20px', fontFamily: 'Inria Sans' }}>
                        <p style={{ textAlign: 'center', color: '#5958C7', fontSize: '48px', fontFamily: 'Inria Sans' }}>Drop us a message</p>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', fontSize: '24px', color: "#8C8C8C" }}>
                            <div style={{ width: '90%' }}>
                                <Form style={{ marginBottom: '30px' }} onSubmit={handleSubmit} >
                                    <Form.Control className="formControl" type="name" value={name} onChange={(e) => setName(e.target.value)} required placeholder="Name" />
                                    <Form.Control className="formControl" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder="Email" />
                                    <Form.Control className="formControl" type="name" value={company} onChange={(e) => setCompany(e.target.value)} required placeholder="Organisation Name" />
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Control style={{ borderRadius: '10px', fontSize: '20px', marginBottom: '30px' }} value={message} onChange={(e) => setMessage(e.target.value)} as="textarea" placeholder="Message" rows={4} />
                                    </Form.Group>
                                    <Button className="button" type="submit">
                                        Submit</Button>
                                </Form>
                            </div>
                        </div>
                    </Col>
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 4, offset: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ marginBottom: '50px' }}>
                                <p className="head">India Office</p>
                                <div className="desc">
                                    <p>219 Jayanagar</p>
                                    <p>Bangalore India</p>
                                    <p>560041</p>
                                    <p>Email: support@unomok.com</p>
                                    <p>Phone: +91 880707 3737</p>
                                </div>
                            </div>
                            <div style={{ marginBottom: '10px' }}>
                                <p className="head">US Office</p>
                                <div className="desc">
                                    <p>1014 Center Rd Suite</p>
                                    <p>408-B Wilmington</p>
                                    <p>DE 19805 USA</p>
                                    <p>Email: support@unomok.com</p>
                                    <p>Phone: +1 - 812 -00281354</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row >
                <div>
                    <p className='heading'>Hear from our clients</p>
                </div>
                <Slider />
            </div >
        </Layout>
    )
};

export default Contact;
